import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../components/Footer/Footer';
import { MemberHeader } from '../components/MemberHeader/MemberHeader';
import { AuthContext } from '../services/auth';
import { URL_LOGIN } from '../utils/constant';

interface DefaultLayoutProps {
  children?: React.ReactNode;
}

export const MemberLayout = ({ children }: DefaultLayoutProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  if (!isAuthenticated) {
    navigate(URL_LOGIN);
  }

  return (
    <div className="layout">
      <MemberHeader onLogout={logout} />
      {children}
      <Footer />
    </div>
  );
};

import { createContext, ReactNode, useEffect, useState } from 'react';
import { LOCALSTORAGE_DEFAULT_IP_INFO } from '../utils/constant';
import { initialIpInfo } from '../utils/initial-values';
import { IpInfo } from '../utils/types';

const { REACT_APP_IPINFO_URL_TOKEN } = process.env;

export const IpInfoContext = createContext(initialIpInfo);

export const IpInfoProvider = ({ children }: { children: ReactNode }) => {
  const [ipInfo, setIpInfo] = useState(initialIpInfo);

  const storedIpInfo: IpInfo = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_DEFAULT_IP_INFO) ||
      JSON.stringify(initialIpInfo)
  );

  const fetchIpInfo = async () => {
    await fetch(`${REACT_APP_IPINFO_URL_TOKEN}`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        localStorage.setItem(
          LOCALSTORAGE_DEFAULT_IP_INFO,
          JSON.stringify(jsonResponse)
        );
        setIpInfo(jsonResponse);
      });
  };

  useEffect(() => {
    if (storedIpInfo.country !== '') {
      setIpInfo(storedIpInfo);
    }
    if (storedIpInfo.country === '') {
      fetchIpInfo();
    }
  }, []);

  return (
    <IpInfoContext.Provider value={ipInfo}>{children}</IpInfoContext.Provider>
  );
};

import { Box, Center, Container, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';

export const MemberCard = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box
        backgroundColor="blackAlpha.300"
        borderColor="green.500"
        borderRadius="3xl"
        borderWidth="2px"
        color="brand.400"
        textAlign="center"
        my="4"
        pt="8">
        <Box
          borderColor="brand.200"
          borderRadius="3xl"
          borderWidth="2px"
          h="2"
          w="16"
          mb="8"
          mx="auto"></Box>

        <Center mb="8">
          <BonavendoLogo />
        </Center>

        <Flex
          mb="8"
          px="4"
          textAlign="left"
          justifyContent="center"
          alignItems="center">
          <Box mr="2" minW="75px" w="30%">
            <Image
              src="https://via.placeholder.com/75C/E1FEFE"
              height="auto"
              width="100%"
              borderRadius="full"
              alt="Max Mustermann"
            />
          </Box>
          <Box width="70%">
            <Text noOfLines={2} fontWeight="bold">
              Alexander Konrad Johannes Schmidt
            </Text>
            <Text fontSize="xs">alexander.konrad.johannes@schmidt.eco</Text>
          </Box>
        </Flex>

        <Box mb="8">
          <Text fontSize="xl" fontStyle="italic">
            {t('common.claimPart1')}
            <br />
            {t('common.claimPart2')}
          </Text>
        </Box>

        <Box mb="8">
          Aktives Mitglied seit
          <br />
          17. Juli 2022
        </Box>

        <Box
          backgroundColor="brand.200"
          borderBottomRadius="2xl"
          color="black"
          fontSize="3xl"
          p="4">
          #1234567
        </Box>
      </Box>
    </Container>
  );
};

import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Button } from './components/Button';
// import { Form } from './components/Form';
// import { FormLabel } from './components/FormLabel';
import { Input } from './components/Input';
import { NumberInput } from './components/NumberInput';
// import { Link } from './components/Link';
// import { Modal } from './components/Modal';
import { Select } from './components/Select';
// import { Switch } from './components/Switch';
import { Textarea } from './components/Textarea';
import '@fontsource/karla';

export const bonavendoTheme = extendTheme({
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },

  fonts: {
    heading: 'Vollkorn, serif',
    body: 'Karla, sans-serif',
  },

  colors: {
    red: {
      '50': '#FCE8ED',
      '100': '#F7C0CC',
      '200': '#F297AB',
      '300': '#EC6F89',
      '400': '#E74668',
      '500': '#E21D47',
      '600': '#B51739',
      '700': '#87122B',
      '800': '#5A0C1D',
      '900': '#2D060E',
    },
    green: {
      '50': '#F0F3F4',
      '100': '#D5DFE1',
      '200': '#BACACE',
      '300': '#A0B5BB',
      '400': '#85A0A8',
      '500': '#6A8B95',
      '600': '#556F77',
      '700': '#405459',
      '800': '#2A383C',
      '900': '#151C1E',
    },
    blue: {
      '50': '#E8F8FD',
      '100': '#BEEBF9',
      '200': '#94DDF5',
      '300': '#6AD0F1',
      '400': '#40C3ED',
      '500': '#17B6E8',
      '600': '#1292BA',
      '700': '#0E6D8B',
      '800': '#09495D',
      '900': '#05242E',
    },
    yellow: {
      '50': '#FCF5E9',
      '100': '#F7E4C0',
      '200': '#F1D398',
      '300': '#ECC26F',
      '400': '#E6B147',
      '500': '#E1A01E',
      '600': '#B48018',
      '700': '#876012',
      '800': '#5A400C',
      '900': '#2D2006',
    },
    bonavendo: {
      red: '#e63e62', // #FF1F40, #e63e62
      green: '#86a1a9', // #77FA48, #1fff6e
      lightblue: '#8EC7D2',
      blue: '#0D6986', // #0D6986, #6868ac
      darkblue: '#07485B',
      yellow: '#ecc371',
    },
    primaryFontColor: {
      lightMode: 'gray.700',
      darkMode: 'gray.200',
    },
    secondaryFontColor: {
      lightMode: 'gray.600',
      darkMode: 'gray.400',
    },
  },

  shadows: {
    outline: '#77FA48',
  },

  styles: {
    // @ts-ignore
    global: (props) => ({
      html: { minHeight: '100%' },
      body: {
        backgroundColor: mode('green.50', 'green.800')(props),
        color: mode('gray.700', 'gray.200')(props),
        minHeight: '100%',
      },
      'ul, ol': {
        paddingStart: 5,
      },
    }),
  },

  components: {
    Button,
    // Form,
    // FormLabel,
    Input,
    NumberInput,
    // Link,
    // Modal,
    Select,
    // Switch,
    Textarea,
  },
});

import { useContext } from 'react';
import { MemberDashboard } from '../components/MemberDashboard/MemberDashboard';
import { AuthContext } from '../services/auth';

export const MemberArea: React.FC = () => {
  const { userData } = useContext(AuthContext);

  console.log({ userData });

  return (
    <main className="member-area">
      <section>
        <MemberDashboard userData={userData} />
      </section>
    </main>
  );
};

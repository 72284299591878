import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';
import { IpInfoContext } from '../../services/ipinfo';
import { useCountries } from '../../hooks/countries';
import { useCurrencies } from '../../hooks/currencies';
import {
  createAddress,
  createCompany,
  createCompanyUser,
  createInvoice,
  createInvoiceMembership,
  createMembership,
  createMembershipInvoice,
  createUser,
  registerUser,
} from '../../services/directus';
import { scrollToView } from '../../utils/browser';
import { focusField, initForm } from '../../utils/form';
import { initialCompany } from '../../utils/initial-values';
import { LanguageCodes } from '../../utils/language';
import { Addresses, Companies, DirectusUsers } from '../../utils/types';
import { Address } from '../Address/Address';
import { AlertBox } from '../AlertBox/AlertBox';
import { RegisterAgentInfo } from '../RegisterAgentInfo/RegisterAgentInfo';
import { SwitchTermsAndConditions } from '../SwitchTermsAndConditions/SwitchTermsAndConditions';
import { User } from '../User/User';

const { REACT_APP_COST_OF_MEMBERSHIP_PER_MONTH } = process.env;

const LOCALSTORAGE_COMPANY_DATA = 'bonavendoCompanyData';
const LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS =
  'bonavendoCompanyTermsAndConditionsChecked';
const FOCUS_FIRST_FIELD = 'firstName';

export const RegisterCompanyForm = ({ agent }: { agent?: DirectusUsers }) => {
  const { i18n, t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const { country } = useContext(IpInfoContext);
  const countries = useCountries(i18n.language as LanguageCodes);
  const currencies = useCurrencies(i18n.language as LanguageCodes);

  const storedCompanyData: Companies = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_COMPANY_DATA) ||
      JSON.stringify(initialCompany)
  );
  const storedTermsAndConditionsChecked = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS) || 'false'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    storedTermsAndConditionsChecked === true || false
  );
  const [successfullyAdded, setSuccessfullyAdded] = useState(false);
  const [companyData, setCompanyData] = useState<Companies>({
    manager: storedCompanyData.manager,
    address: storedCompanyData.address,
    name: storedCompanyData.name,
    number_of_employees: storedCompanyData.number_of_employees
      ? storedCompanyData.number_of_employees
      : 1,
  });
  const [paymentAmount, setPaymentAmount] = useState('12');

  const currentCountry = countries.find(
    // @ts-ignore
    (c) => c.id === Number(companyData?.address?.country)
  );
  const currentCurrency = currencies?.find(
    (c) => c.id === currentCountry?.currency
  );

  const cleanupCompanyData = () => {
    setCompanyData({ ...initialCompany });
    setSuccessfullyAdded(true);
    setIsLoading(false);
    setIsChecked(false);
    localStorage.removeItem(LOCALSTORAGE_COMPANY_DATA);
    localStorage.removeItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    localStorage.setItem(
      LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS,
      JSON.stringify(!isChecked)
    );
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: 'user' | 'company' | 'address'
  ) => {
    const manager = companyData.manager as DirectusUsers;
    const userObject = {
      ...manager,
      ...(type === 'user'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const company = companyData;
    const companyObject = {
      ...company,
      ...(type === 'company'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const address = companyData.address as Addresses;
    const addressObject = {
      ...address,
      ...(type === 'address'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const changedCompanyData = {
      ...companyData,
      ...companyObject,
      manager: {
        ...userObject,
      },
      address: {
        ...addressObject,
      },
    };

    setCompanyData(changedCompanyData);
    localStorage.setItem(
      LOCALSTORAGE_COMPANY_DATA,
      JSON.stringify(changedCompanyData)
    );
  };

  const handleNumberOfEmployees = (
    valueAsString: string,
    valueAsNumber: number
  ) => {
    handlePaymentAmount(valueAsNumber);

    const changedCompanyData = {
      ...companyData,
      number_of_employees: valueAsNumber,
    };
    setCompanyData(changedCompanyData);
    localStorage.setItem(
      LOCALSTORAGE_COMPANY_DATA,
      JSON.stringify(changedCompanyData)
    );
  };

  const handlePaymentAmount = (number_of_employees: number) => {
    REACT_APP_COST_OF_MEMBERSHIP_PER_MONTH &&
      number_of_employees >= 0 &&
      setPaymentAmount(
        String(
          12 *
            parseInt(REACT_APP_COST_OF_MEMBERSHIP_PER_MONTH) *
            number_of_employees
        )
      );

    if (number_of_employees === 0) {
      setPaymentAmount('0');
    }
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    console.log({ companyData });

    Promise.allSettled([
      registerUser(companyData.manager as DirectusUsers),
      createAddress(companyData.address as Addresses),
    ])
      .then(([responseUser, responseAddress]: any) => {
        console.log({ responseUser });
        console.log({ responseAddress });
        createCompany({
          ...companyData,
          manager: responseUser.value.id,
          agent: agent?.id,
          address: responseAddress.value.id,
        }).then((responseCreateCompany: any) => {
          console.log({ responseCreateCompany });
          createUser({
            user: { id: responseUser.value.id },
            agent: agent?.id,
            company: responseCreateCompany.id,
          }).then((responseCreateUser: any) => {
            console.log({ responseCreateUser });
            createCompanyUser({
              users_id: responseCreateUser.id,
              companies_id: responseCreateCompany.id,
            });
            createMembership({ user: responseCreateUser?.id }).then(
              (responseCreateMembership: any) => {
                console.log({ responseCreateMembership });
                createInvoice({
                  recipient: 'company',
                  company: responseCreateCompany.id,
                  number_of_employees: companyData.number_of_employees,
                }).then((responseCreateInvoice: any) => {
                  console.log({ responseCreateInvoice });
                  createMembershipInvoice({
                    memberships_id: responseCreateMembership?.id,
                    invoices_id: responseCreateInvoice?.id,
                  }).then(() => {
                    createInvoiceMembership({
                      invoices_id: responseCreateInvoice?.id,
                      memberships_id: responseCreateMembership?.id,
                    }).then(() => {
                      cleanupCompanyData();
                      scrollToView('register-success');
                    });
                  });
                });
              }
            );
          });
        });
      })
      .catch((error) => {
        console.log('FAILED!', error);
      });
  };

  useEffect(() => {
    initForm();
    focusField(FOCUS_FIRST_FIELD);
    if (storedCompanyData.number_of_employees) {
      handlePaymentAmount(storedCompanyData.number_of_employees);
    }
  }, []);

  return (
    <Container mb={marginBetweenSections}>
      <Heading mt="20" mb="5">
        {t('form.titleAddCompany')}
      </Heading>

      {successfullyAdded && (
        <AlertBox
          status="success"
          title={t('common.alert.successfullyRegisteredTitle') || undefined}
          description={t('common.alert.successfullyRegisteredDescription')}
          onClose={() => setSuccessfullyAdded(false)}
        />
      )}

      {!successfullyAdded && agent && <RegisterAgentInfo agent={agent} />}

      {!successfullyAdded && (
        <form onSubmit={handleSubmit}>
          <Stack mb={marginBetweenSections} spacing="5">
            <Heading size="md">{t('form.subtitlePersonResponsible')}</Heading>

            <User
              user={companyData.manager as DirectusUsers}
              onChange={(event) => handleChange(event, 'user')}
            />

            <Heading size="md">{t('form.subtitleCompanyInformation')}</Heading>

            <FormControl>
              <FormLabel htmlFor="name">{t('form.companyName')}</FormLabel>
              <Input
                id="name"
                type="text"
                value={companyData.name}
                onChange={(event) => handleChange(event, 'company')}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="number_of_employees">
                {t('form.numberOfEmployees')}
              </FormLabel>
              <NumberInput
                id="number_of_employees"
                value={companyData.number_of_employees}
                onChange={handleNumberOfEmployees}
                step={1}
                min={1}
                allowMouseWheel>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>{t('form.numberOfEmployeesHelp')}</FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="paymentAmount">
                {t('form.paymentAmount')}
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  color="gray.400"
                  pointerEvents="none"
                  fontSize="1.2em"
                  children={currentCurrency?.currency_code}
                />
                <Input id="paymentAmount" value={paymentAmount} disabled />
                <InputRightElement
                  color="gray.400"
                  children={t('common.year')}
                  pr="5"
                />
              </InputGroup>
              <FormHelperText>{t('form.paymentAmountHelp')}</FormHelperText>
            </FormControl>

            <Address
              address={companyData.address as Addresses}
              countries={countries}
              defaultCountry={country}
              showHelperText={true}
              onChange={(event) => handleChange(event, 'address')}
            />

            <SwitchTermsAndConditions
              isChecked={isChecked}
              onChange={handleChecked}
            />

            <Button mt="4" size="lg" type="submit" isLoading={isLoading}>
              {t('form.registerCompany')}
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
};

import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Flex,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChangeEvent, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../contexts/GlobalContext';
import { AuthContext } from '../../services/auth';
import { URL_DASHBOARD, URL_MEMBER } from '../../utils/constant';
import { initialLogin } from '../../utils/initial-values';
import { AlertBox } from '../AlertBox/AlertBox';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import { EmailAddress } from '../EmailAddress/EmailAddress';
import { Password } from '../Password/Password';

const {
  REACT_APP_MEMBER_USER_ROLE_ID,
  REACT_APP_MEMBER_MANAGER_ROLE_ID,
  REACT_APP_MEMBER_AGENT_ROLE_ID,
} = process.env;

export const LoginForm = () => {
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const {
    isLoading,
    isAuthenticated,
    errorStatus,
    userData,
    closeErrorStatus,
    login,
  } = useContext(AuthContext);

  const [loginData, setLoginData] = useState(initialLogin);

  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({ email: loginData.email, password: loginData.password });
  };

  if (isAuthenticated) {
    switch (userData.role) {
      case REACT_APP_MEMBER_USER_ROLE_ID:
        navigate(URL_MEMBER);
        break;

      case REACT_APP_MEMBER_MANAGER_ROLE_ID:
      case REACT_APP_MEMBER_AGENT_ROLE_ID:
        navigate(URL_DASHBOARD);
        break;

      default:
        break;
    }
  }

  return (
    <Container maxW={360}>
      <Box mt="20" mb="10">
        <Link href="/">
          <BonavendoLogo
            color={useColorModeValue('#e63e62', 'white')}
            margin="0 auto"
          />
        </Link>
      </Box>

      {errorStatus && (
        <AlertBox
          status="error"
          description={t('error.' + errorStatus)}
          onClose={closeErrorStatus}
        />
      )}

      <form onSubmit={handleSubmit}>
        <Stack mb="10" spacing="5">
          <EmailAddress
            value={`${loginData.email}`}
            onChange={(event) => handleChange(event)}
          />
          <Password value={`${loginData.password}`} onChange={handleChange} />
          <Button mt="4" size="lg" type="submit" isLoading={isLoading}>
            {t('common.login')}
          </Button>
        </Stack>
      </form>

      <Box mb="10" textAlign="center">
        <Trans i18nKey="common.noAccount">
          New to bonavendo?&nsbp;
          <Link href="/register" textDecoration="underline">
            Join Now!
          </Link>
        </Trans>
      </Box>

      <Flex fontSize="xs" mb={marginBetweenSections}>
        <Link noOfLines={1} href="/page/imprint">
          {t('common.imprint')}
        </Link>
        <Spacer />
        <Link noOfLines={1} href="/page/privacy">
          {t('common.dataProtection')}
        </Link>
        <Spacer />
        <Link noOfLines={1} href="/page/termsofuse">
          {t('common.termsOfUse')}
        </Link>
      </Flex>
    </Container>
  );
};

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { icon } from 'leaflet';
import { Box, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import 'leaflet/dist/leaflet.css';
import './map.scss';
import { useTranslation } from 'react-i18next';
import { Addresses, Companies } from '../../utils/types';

const Icon = icon({
  iconUrl: '/bonavendo-map-pin.svg',
  iconSize: [30, 38],
});

interface MapProps {
  company: Companies;
  address: Addresses;
}

export const Map = ({ company, address }: MapProps) => {
  const { t } = useTranslation();

  const coordinates: number[] = [
    // @ts-ignore
    company?.map?.coordinates[1],
    // @ts-ignore
    company?.map?.coordinates[0],
  ];

  return (
    <Box h={[300, 400, 500]}>
      {coordinates && (
        <MapContainer
          // @ts-ignore
          center={coordinates}
          zoom={13}
          scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* @ts-ignore */}
          <Marker position={coordinates} icon={Icon}>
            <Popup>
              <Text>
                {/* @ts-ignore */}
                <address>
                  <strong>{company.name}</strong>
                  <br />
                  {address.address_area && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: address.address_area.replace(
                          /\r?\n/g,
                          '<br />'
                        ),
                      }}
                    />
                  )}
                  {address.address_area && <br />}
                  {address.postal_code} {address.city_location}
                  ,&nbsp;
                  {address.state}
                  <br />
                  {address.country}
                </address>
              </Text>
              <Link
                variant="outline"
                // @ts-ignore
                href={`geo:${coordinates}`}
                isExternal>
                {t('common.calculateRoute')} <ExternalLinkIcon />
              </Link>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </Box>
  );
};

import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  InputGroup,
  Input,
  InputRightAddon,
  FormHelperText,
  DarkMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SearchWhatAndWhere = () => {
  const { t } = useTranslation();

  const [inputWhat, setInputWhat] = useState('');
  const [inputWhere, setInputWhere] = useState('');
  // console.log(inputWhat);
  // console.log(inputWhere);

  const handleInputWhatChange = (e: any) => setInputWhat(e.target.value);
  const handleInputWheretChange = (e: any) => setInputWhere(e.target.value);

  // const isError = inputWhat === '' || inputWhere === '';
  const isError = false;

  const normalBorderColor = useColorModeValue(
    'whiteAlpha.800',
    'whiteAlpha.300'
  );

  return (
    <FormControl>
      <DarkMode>
        <InputGroup>
          <Input
            borderColor={normalBorderColor}
            _hover={{
              borderColor: useColorModeValue('white', 'whiteAlpha.500'),
            }}
            placeholder={t('form.placeholder.what') || ''}
            _placeholder={{ color: 'white' }}
            type="search"
            onChange={handleInputWhatChange}
          />
          <Input
            borderRadius="0"
            borderColor={normalBorderColor}
            _hover={{
              borderColor: useColorModeValue('white', 'whiteAlpha.500'),
            }}
            ml="1px"
            mr="2px"
            placeholder={t('form.placeholder.where') || ''}
            _placeholder={{ color: 'white' }}
            type="search"
            onChange={handleInputWheretChange}
          />
          <InputRightAddon
            border={'2px solid'}
            borderColor={'inherit'}
            borderEndRadius={'md'}
            opacity={'0.8'}
            children={
              <Flex
                alignItems="center"
                borderRightRadius="base"
                justifyContent="center"
                h="100%"
                w="48px">
                <SearchIcon h="5" w="5" color={'white'} opacity={'0.8'} />
              </Flex>
            }
          />
        </InputGroup>
        {isError && (
          <FormHelperText color={'white'}>
            {t('form.validation.noHitForSearch')}
          </FormHelperText>
        )}
      </DarkMode>
    </FormControl>
  );
};

import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { globalDefaultContext } from '../../contexts/GlobalContext';

export const About = () => {
  return (
    <Box
      py={globalDefaultContext.marginBetweenSections}
      bgColor={useColorModeValue('green.100', 'green.900')}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading>Das ist bonavendo</Heading>
        <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize={'xl'}>
          Wir haben es uns zur Aufgabe gemacht Konsumenten mit Direkterzeugern
          zu verbinden. Der Online-Handel über Amazon und Co. nahm in den
          letzten Jahren bedenklich zu. Um unsre Region, in der wir leben, zu
          schützen und zu stärken, bestimmen wir, welche Produkte wir kaufen und
          somit, wohin die Unternehmergewinne fliessen.
        </Text>
      </Stack>
    </Box>
  );
};

import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  useColorModeValue,
  Image,
  Hide,
  Show,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { globalDefaultContext } from '../../contexts/GlobalContext';

const features = [
  {
    id: 1,
    title: 'Give back to the neighborhood',
    text: 'Small business owners are often members of your community. They are more likely to contribute to local causes and develop partnerships with other local residents. They serve as community hubs.',
  },
  {
    id: 2,
    title: 'Help create jobs',
    text: 'As small businesses increase profit, they can hire more local residents. According to the small business administration, small business have created 55 % of all jobs since the 1970s.',
  },
  {
    id: 3,
    title: 'Fewer crowds',
    text: 'Tired of navigating jam-packed malls? Small local businesses tend to be more low-key, less crowded and have more time for personal attention.',
  },
  {
    id: 4,
    title: 'Find unique products',
    text: "Small businesses often stock items that can't be found in big box stores. Therefore, you're more apt to find one-of-a-kind or locally made items, rather than mass-produced products.",
  },
  {
    id: 5,
    title: 'Keep community business diverse',
    text: 'By design, strip malls tend to include the same types of familiar stores with similar items. Supporting small independent businesses keeps variety and diversity in the community.',
  },
];

export const Features = () => {
  return (
    <Box py={globalDefaultContext.marginBetweenSections}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading>5 Gründe lokal oder direkt einzukaufen</Heading>
        <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize={'xl'}>
          Small business have a big effect on the nation's economy. Companies
          with 100 employees or less make up 99.7% of all businesses and employ
          more than halt (56%) of the nation's workforce, including your family
          and friends.
        </Text>
      </Stack>

      <Box px={10}>
        <Show below="md">
          <Image
            src={useColorModeValue(
              'https://via.placeholder.com/320x480/D5DFE1/F0F3F4/?text=Illustration+Placeholder',
              'https://via.placeholder.com/320x480/151C1E/F0F3F4/?text=Illustration+Placeholder'
            )}
            w={'full'}
            maxW={'6xl'}
            mx={'auto'}
            my={10}
            borderRadius={'full'}
          />
        </Show>
        <Hide below="md">
          <Image
            src={useColorModeValue(
              'https://via.placeholder.com/1000x140/D5DFE1/F0F3F4/?text=Illustration+Placeholder',
              'https://via.placeholder.com/1000x140/151C1E/F0F3F4/?text=Illustration+Placeholder'
            )}
            w={'full'}
            maxW={'6xl'}
            mx={'auto'}
            my={10}
            borderRadius={'full'}
          />
        </Hide>
      </Box>

      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, lg: 5 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={useColorModeValue('gray.600', 'gray.300')}>
                  {feature.text}
                </Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

import { useEffect, useState } from 'react';
import { RegisterMemberForm } from '../components/RegisterMemberForm/RegisterMemberForm';
import { readCompany, readUser } from '../services/directus';
import { DirectusUsers, Status } from '../utils/types';

export const RegisterMember = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const aid = queryParams.get('aid'); // agentId
  const cid = queryParams.get('cid'); // companyId
  const [agent, setAgent] = useState<DirectusUsers>();
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [isDisplayingAddress, setIsDisplayingAddress] = useState(
    cid ? false : true
  );

  useEffect(() => {
    if (aid) {
      readUser(aid)
        .then((responseUser) => {
          if (responseUser) {
            const userAgent: DirectusUsers = {
              id: aid,
              first_name: responseUser.first_name || undefined,
              last_name: responseUser.last_name || undefined,
              email: responseUser.email || undefined,
            };

            setAgent(userAgent);
          }
        })
        .catch(() => {
          setAgent(undefined);
        });
    }
    if (cid) {
      readCompany(cid)
        .then((responseCompany: any) => {
          if (
            responseCompany &&
            responseCompany.data &&
            (responseCompany.data[0].status === Status.active ||
              responseCompany.data[0].status === Status.published)
          ) {
            setCompanyId(responseCompany.data[0].id);
            setCompanyName(responseCompany.data[0].name);
            setIsDisplayingAddress(false);
            if (responseCompany.data[0].agent) {
              readUser(responseCompany.data[0].agent).then(
                (responseUser: any) => {
                  const userAgent: DirectusUsers = {
                    id: responseCompany.data[0].agent,
                    first_name: responseUser.first_name,
                    last_name: responseUser.last_name,
                    email: responseUser.email,
                  };

                  setAgent(userAgent);
                }
              );
            }
          } else {
            setAgent(undefined);
            setCompanyId(0);
            setIsDisplayingAddress(true);
          }
        })
        .catch(() => {
          setAgent(undefined);
          setCompanyId(0);
          setIsDisplayingAddress(true);
        });
    }
  }, []);

  return (
    <main className="register-member">
      <section>
        <RegisterMemberForm
          agent={agent}
          companyId={companyId}
          companyName={companyName}
          isDisplayingAddress={isDisplayingAddress}
        />
      </section>
    </main>
  );
};

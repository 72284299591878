import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  variants: {
    solid: {
      bg: 'bonavendo.green',
      color: 'white',
      colorScheme: 'green',
      fontWeight: 'normal',
      _hover: {
        bg: 'green.300',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
  },
};

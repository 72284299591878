import React from 'react';
import {
  DarkMode,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  MdOutlineAccountCircle,
  MdOutlineCardMembership,
  MdOutlineHome,
  MdOutlineLogout,
  MdOutlineSettings,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  URL_MEMBER,
  URL_MEMBER_CARD,
  URL_MEMBER_PROFILE,
  URL_MEMBER_SETTINGS,
} from '../../utils/constant';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

export interface MemberMenuProps {
  onLogout: (event: React.MouseEvent<HTMLElement>) => void;
}

export const MemberMenu = ({ onLogout }: MemberMenuProps) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.900', 'white');

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <DarkMode>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Options"
              icon={
                isOpen ? (
                  <CloseIcon h="4" w="6" />
                ) : (
                  <HamburgerIcon h="6" w="6" />
                )
              }
              variant="outline"
            />
          </DarkMode>
          <MenuList color={textColor}>
            <MenuItem as={NavLink} to={URL_MEMBER}>
              <Icon as={MdOutlineHome} mr="2" />
              {t('common.memberArea')}
            </MenuItem>
            <MenuItem as={NavLink} to={URL_MEMBER_CARD}>
              <Icon as={MdOutlineCardMembership} mr="2" />
              {t('common.memberCard')}
            </MenuItem>
            {/* <MenuItem as={NavLink} to={URL_MEMBER_PROFILE}>
              <Icon as={MdOutlineAccountCircle} mr="2" />
              {t('common.profile')}
            </MenuItem> */}
            {/* <MenuItem as={NavLink} to={URL_MEMBER_SETTINGS}>
              <Icon as={MdOutlineSettings} mr="2" />
              {t('common.settings')}
            </MenuItem> */}
            <MenuItem as={NavLink} to={URL_MEMBER} onClick={onLogout}>
              <Icon as={MdOutlineLogout} mr="2" />
              {t('common.logout')}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

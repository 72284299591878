import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { directusWithStaticToken } from '../services/directus';
import { Status } from '../utils/types';
import { getAddress, handleAddress } from '../utils/address';
import { useTranslation } from 'react-i18next';
import { Loading } from '../components/Loading/Loading';
import { CompanyCard } from '../components/CompanyCard/CompanyCard';
import { Addresses, Companies } from '../utils/types';
import { initialAddress } from '../utils/initial-values';

const { REACT_APP_ITEMS_COMPANIES } = process.env;

export interface CompaniesProps {
  data: Companies[];
}

export const Company = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { slug } = useParams();
  const [company, setCompany] = useState<Companies>();
  const [address, setAddress] = useState(initialAddress);
  const currentLanguage = i18n.language;
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    async function fetchData() {
      let companyResponse: Companies;
      let companiesResponse: CompaniesProps;

      try {
        if (slug) {
          companiesResponse = (await directusWithStaticToken
            .items(`${REACT_APP_ITEMS_COMPANIES}`)
            .readByQuery({
              limit: 1,
              search: slug,
              filter: {
                slug: {
                  _eq: slug,
                },
              },
            })) as CompaniesProps;

          companyResponse = companiesResponse.data[0];

          if (companyResponse.status !== Status.published) {
            navigate('/404', { replace: true });
          }

          setCompany({
            company_id: companyResponse.company_id,
            slug: companyResponse.slug,
            status: companyResponse.status,
            agent: companyResponse.agent,
            sponsored_users: companyResponse.sponsored_users,
            name: companyResponse.name,
            address: companyResponse.address,
            map: companyResponse.map,
            url: companyResponse.url,
            email_address: companyResponse.email_address,
            phone_number: companyResponse.phone_number,
            industry: companyResponse.industry,
            tags: companyResponse.tags,
            intro: companyResponse.intro,
            cover_image: companyResponse.cover_image,
            advantages: companyResponse.advantages,
            description: companyResponse.description,
          });

          getAddress(Number(companyResponse.address)).then((response) => {
            handleAddress(response as Addresses, currentLanguage).then(
              (response) => {
                if (response[0]) {
                  setAddress(response[0]);
                }
              }
            );
          });
        }
      } catch (err) {
        navigate('/404', { replace: true });
      }
    }

    fetchData();
  }, [currentLanguage, navigate, slug]);

  return (
    <main className="company">
      <section>
        {!company && <Loading />}
        {company && company.status === Status.published && (
          <CompanyCard company={company} address={address} />
        )}
      </section>
    </main>
  );
};

import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import GlobalContext from '../../contexts/GlobalContext';
import { Card } from '../Card/Card';
import { getAssetURL } from '../../utils/get-asset-url';
import { Map } from '../Map/Map';
import { Addresses, Companies } from '../../utils/types';

interface CompanyCardProps {
  company: Companies;
  address: Addresses;
}

export const CompanyCard = ({ company, address }: CompanyCardProps) => {
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const position = [47.515230129648415, 9.429754164586285];
  // const position = [
  //   company.map?.coordinates[1],
  //   company.map?.coordinates[0],
  // ];

  console.log({ company });
  console.log({ address });

  return (
    <Container>
      <Card mb={marginBetweenSections}>
        {company.cover_image && (
          <Box h="auto" w="100%" overflow="hidden">
            <Image
              src={getAssetURL(company.cover_image as string) || undefined}
              objectFit="cover"
              h="100%"
              w="100%"
              zIndex="1"
            />
          </Box>
        )}

        <Box p="5">
          <Stack direction={'row'} mb="5">
            {company.tags?.map((tag) => (
              <Badge key={tag} colorScheme={'green'}>
                {tag}
              </Badge>
            ))}
          </Stack>

          <Heading mb="2">{company.name}</Heading>

          <Text mb="5">{company.intro}</Text>

          <Heading size="sm" mb="2">
            {t('form.benefitsForMembers')}
          </Heading>

          <Stack direction={'row'} mb="5">
            {company.advantages?.map((tag) => (
              <Badge key={tag} variant={'outline'}>
                {tag}
              </Badge>
            ))}
          </Stack>

          <SimpleGrid columns={[1, 2]} spacing={5} mb="5">
            <Box>
              {/* @ts-ignore */}
              <address>
                {address.address_area && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: address.address_area.replace(/\r?\n/g, '<br />'),
                    }}
                  />
                )}
                <br />
                {address.city_location} {address.postal_code}
                <br />
                {address.state}
                <br />
                {address.country}
              </address>
            </Box>
            <Box>
              <address>
                <Link href={`mailto:${company.email_address}`}>
                  {company.email_address}
                </Link>
                <br />
                <Link href={`tel:${company.phone_number}`}>
                  {company.phone_number}
                </Link>
              </address>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={5}>
            <Button onClick={onOpen}>{t('common.details')}</Button>

            <Link
              as={Button}
              href={`geo:${position}`}
              color={'white'}
              _hover={{ textDecoration: 'none' }}
              target="_blank">
              {t('common.route')} <ExternalLinkIcon mx="1" />
            </Link>
          </SimpleGrid>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="full"
            scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent w={['100%', '62%', '38%']}>
              <ModalHeader>{company.name}</ModalHeader>
              <ModalCloseButton />
              {company.description && (
                <ModalBody pt="0">
                  <Stack spacing={3}>
                    <Markdown>{company.description}</Markdown>
                  </Stack>
                </ModalBody>
              )}
            </ModalContent>
          </Modal>
        </Box>

        <Map company={company} address={address} />
      </Card>
    </Container>
  );
};

import { DefaultLayout } from './layouts/Default';
import { Route, Routes } from 'react-router-dom';

import { Company } from './pages/Company';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Page } from './pages/Page';
import { RegisterMember } from './pages/RegisterMember';
import { RegisterCompany } from './pages/RegisterCompany';
import { Login } from './pages/Login';
import { MemberArea } from './pages/MemberArea';
import { Dashboard } from './pages/Dashboard';
import { MemberCardPage } from './pages/MemberCardPage';

import {
  URL_DASHBOARD,
  URL_LOGIN,
  URL_MEMBER,
  URL_MEMBER_CARD,
  URL_NOT_FOUND,
  URL_PAGE,
  URL_REGISTER_COMPANY,
  URL_REGISTER_MEMBER,
  URL_ROOT,
} from './utils/constant';
import { useContext } from 'react';
import { AuthContext } from './services/auth';
import { MemberLayout } from './layouts/Member';

export const App = () => {
  const { isMember, isManager, isAgent } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        path={URL_ROOT}
        element={
          <DefaultLayout>
            <Home />
          </DefaultLayout>
        }
      />
      <Route
        path={URL_PAGE + '/:slug'}
        element={
          <DefaultLayout>
            <Page />
          </DefaultLayout>
        }
      />
      <Route
        path="/:slug"
        element={
          <DefaultLayout>
            <Company />
          </DefaultLayout>
        }
      />
      <Route
        path={URL_REGISTER_MEMBER}
        element={
          <DefaultLayout>
            <RegisterMember />
          </DefaultLayout>
        }
      />
      <Route
        path={URL_REGISTER_COMPANY}
        element={
          <DefaultLayout>
            <RegisterCompany />
          </DefaultLayout>
        }
      />
      <Route path={URL_LOGIN} element={<Login />} />
      <Route
        path={URL_MEMBER}
        element={
          <MemberLayout>
            <MemberArea />
          </MemberLayout>
        }
      />
      <Route
        path={URL_DASHBOARD}
        element={isAgent || isManager ? <Dashboard /> : <Login />}
      />
      <Route
        path={URL_MEMBER_CARD}
        element={isMember ? <MemberCardPage /> : <Login />}
      />
      <Route path={URL_NOT_FOUND} element={<NotFound />} />
    </Routes>
  );
};

// C REATE
// R EAD
// U PDATE
// D ELETE

import { Directus } from '@directus/sdk';
import slugify from 'react-slugify';
import { InvoiceStatus, Status } from '../utils/types';
import {
  Addresses,
  Companies,
  CompaniesUsers,
  DirectusUsers,
  Invoices,
  InvoicesMemberships,
  Memberships,
  MembershipsInvoices,
  Users,
} from '../utils/types';

const {
  REACT_APP_API_USER_ACCESS_TOKEN,
  REACT_APP_DIRECTUS_URL,
  REACT_APP_ITEMS_ADDRESSES,
  REACT_APP_ITEMS_COMPANIES,
  REACT_APP_ITEMS_INVOICES,
  REACT_APP_ITEMS_INVOICES_MEMBERSHIPS,
  REACT_APP_ITEMS_MEMBERSHIPS,
  REACT_APP_ITEMS_MEMBERSHIPS_INVOICES,
  REACT_APP_ITEMS_COMPANIES_USERS,
  REACT_APP_ITEMS_USERS,
  REACT_APP_ITEMS_COUNTRIES,
  REACT_APP_ITEMS_COUNTRIES_TRANSLATIONS,
  REACT_APP_ITEMS_CURRENCIES,
  REACT_APP_ITEMS_CURRENCIES_TRANSLATIONS,
  REACT_APP_ITEMS_STATES,
  REACT_APP_ITEMS_STATES_TRANSLATIONS,
  REACT_APP_MEMBER_USER_STATUS,
  REACT_APP_MEMBER_USER_ROLE_ID,
  REACT_APP_MEMBER_MANAGER_ROLE_ID,
  REACT_APP_MEMBER_AGENT_ROLE_ID,
} = process.env;

export const userRole = {
  user: REACT_APP_MEMBER_USER_ROLE_ID,
  manager: REACT_APP_MEMBER_MANAGER_ROLE_ID,
  agent: REACT_APP_MEMBER_AGENT_ROLE_ID,
};

// export type UserRole = typeof UserRole[keyof typeof UserRole];

export const directus = new Directus(`${REACT_APP_DIRECTUS_URL}`);

export const directusWithStaticToken = new Directus(
  `${REACT_APP_DIRECTUS_URL}`,
  {
    auth: {
      staticToken: `${REACT_APP_API_USER_ACCESS_TOKEN}`,
    },
  }
);

export const directusWithAccessToken = (token: string) =>
  new Directus(`${REACT_APP_DIRECTUS_URL}`, {
    auth: {
      staticToken: token,
    },
  });

export const loginUser = async (email: string, password: string) => {
  const loginData = {
    email,
    password,
    mode: 'cookie',
  };

  return await directus.auth.login({
    ...loginData,
  });
};

export const refreshToken = () => directus.auth.refresh();

export const refreshTokenIfExpired = () => directus.auth.refreshIfExpired();

export const logoutUser = async () => {
  await directus.auth.logout();
};

export const authToken = directus.auth.token;

export const registerUser = async (user: DirectusUsers) => {
  const userData = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    password: user.password,
    status: REACT_APP_MEMBER_USER_STATUS,
    role: user.role as string,
  };

  return await directusWithStaticToken.users.createOne({
    ...userData,
  });
};

// CREATE

export const createAddress = async (address: Addresses) => {
  if (address.postal_code === '') {
    return { id: 0 };
  }

  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_ADDRESSES}`)
    .createOne({
      ...address,
    });
};

export const createCompany = async (company: Companies) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COMPANIES}`)
    .createOne({
      slug: slugify(company.name),
      status: Status.draft,
      manager: company.manager,
      agent: company.agent,
      number_of_employees: company.number_of_employees,
      name: company.name,
      address: company.address,
    });
};

export const createCompanyUser = async (companyUser: CompaniesUsers) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COMPANIES_USERS}`)
    .createOne(companyUser);
};

export const createInvoice = async (invoice: Invoices) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_INVOICES}`)
    .createOne(invoice);
};

export const createInvoiceMembership = async (
  invoiceMembership: InvoicesMemberships
) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_INVOICES_MEMBERSHIPS}`)
    .createOne(invoiceMembership);
};

export const createMembership = async (membership: Memberships) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_MEMBERSHIPS}`)
    .createOne(membership);
};

export const createMembershipInvoice = async (
  membershipInvoice: MembershipsInvoices
) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_MEMBERSHIPS_INVOICES}`)
    .createOne(membershipInvoice);
};

export const createUser = async (user: Users) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_USERS}`)
    .createOne(user);
};

// READ

export const readCompany = async (companyId: string) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COMPANIES}`)
    .readByQuery({ limit: 1, search: companyId });
};

export const readCompanyBySlug = async (slug: string) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COMPANIES}`)
    .readByQuery({
      limit: 1,
      search: slug,
      filter: {
        slug: {
          _eq: slug,
        },
      },
    });
};

export const readCountries = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COUNTRIES}`)
    .readByQuery({ limit: -1 });
};

export const readCountriesTranslations = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COUNTRIES_TRANSLATIONS}`)
    .readByQuery({ limit: -1 });
};

export const readCurrencies = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_CURRENCIES}`)
    .readByQuery({ limit: -1 });
};

export const readCurrenciesTranslations = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_CURRENCIES_TRANSLATIONS}`)
    .readByQuery({ limit: -1 });
};

export const readInvoice = async (companyId: number) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_INVOICES}`)
    .readByQuery({
      limit: 1,
      company: companyId,
      filter: {
        status: !InvoiceStatus.archived,
      },
    });
};

export const readLoggedInUser = async () => {
  return await directus.users.me.read();
};

export const readStates = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_STATES}`)
    .readByQuery({ limit: -1 });
};

export const readStatesTranslations = async () => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_STATES_TRANSLATIONS}`)
    .readByQuery({ limit: -1 });
};

export const readUser = async (userId: string) => {
  return await directusWithStaticToken.users.readOne(userId);
};

// UPDATE

// export const updateInvoice = async (id: number, invoice: Invoices) => {
//   return await directusWithStaticToken
//     .items(`${REACT_APP_ITEMS_INVOICES}`)
//     .updateOne(id, invoice);
// };

export const updateMembership = async (id: number, membership: Memberships) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_MEMBERSHIPS}`)
    .updateOne(id, membership);
};

export const updateUser = async (user: Users) => {
  const userData: Users = {};

  if (user.agent) {
    userData.agent = user.agent;
  }

  if (user.company) {
    userData.company = user.company;
  }

  if (user.id) {
    return await directusWithStaticToken
      .items(`${REACT_APP_ITEMS_USERS}`)
      .updateOne(user.id, userData);
  }
};

import { useState } from 'react';
import { readCountries, readCountriesTranslations } from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { Countries, CountriesTranslations } from '../utils/types';

export const useCountries = (currentLanguage: LanguageCodes) => {
  const [countries, setCountries] = useState<Countries[]>([]);
  const [language, setLanguage] = useState<LanguageCodes>();

  const fetchCountries = async () =>
    await readCountries().then(async (responseCountries: any) => {
      const countriesData = responseCountries.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setCountries(countriesData);
        setLanguage(currentLanguage);
      }

      if (currentLanguage !== DEFAULT_LANGUAGE) {
        await readCountriesTranslations().then(
          (responseCountriesTranslations: any) => {
            const countriesTranslationsData =
              responseCountriesTranslations.data;

            countriesTranslationsData.map(
              (translation: CountriesTranslations) => {
                if (translation.languages_code === currentLanguage) {
                  const countryIndex = countriesData.findIndex(
                    (country: Countries) =>
                      country.id === translation.countries_id
                  );

                  countriesData[countryIndex].name = translation.name;

                  setCountries(countriesData as Countries[]);
                  setLanguage(currentLanguage);
                }
              }
            );
          }
        );
      }
    });

  if (currentLanguage !== language) {
    fetchCountries();
  }

  return countries;
};

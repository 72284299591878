import { useState } from 'react';
import {
  readCurrencies,
  readCurrenciesTranslations,
} from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { Currencies, CurrenciesTranslations } from '../utils/types';

export const useCurrencies = (currentLanguage: LanguageCodes) => {
  const [currencies, setCurrencies] = useState<Currencies[]>([]);
  const [language, setLanguage] = useState<LanguageCodes>();

  const fetchCurrencies = async () =>
    await readCurrencies().then(async (responseCountries: any) => {
      const currenciesData = responseCountries.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setCurrencies(currenciesData);
        setLanguage(currentLanguage);
      }

      if (currentLanguage !== DEFAULT_LANGUAGE) {
        await readCurrenciesTranslations().then(
          (responseCurrenciesTranslations: any) => {
            const currenciesTranslationsData =
              responseCurrenciesTranslations.data;

            currenciesTranslationsData.map(
              (translation: CurrenciesTranslations) => {
                if (translation.languages_code === currentLanguage) {
                  const currencyIndex = currenciesData.findIndex(
                    (currency: Currencies) =>
                      currency.id === translation.currencies_id
                  );

                  currenciesData[currencyIndex].name =
                    translation.currency_name;

                  setCurrencies(currenciesData as Currencies[]);
                  setLanguage(currentLanguage);
                }
              }
            );
          }
        );
      }
    });

  if (currentLanguage !== language) {
    fetchCurrencies();
  }

  return currencies;
};

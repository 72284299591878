import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useStates } from '../../hooks/states';
import { initialIpInfo } from '../../utils/initial-values';
import { LanguageCodes } from '../../utils/language';
import { Addresses, Countries } from '../../utils/types';

export interface AddressProps {
  address: Addresses;
  countries: Countries[];
  defaultCountry?: string;
  showHelperText?: boolean;
  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
}

export const Address = ({
  address,
  countries,
  defaultCountry = initialIpInfo.country,
  showHelperText = false,
  onChange,
}: AddressProps) => {
  const { i18n, t } = useTranslation();
  const countryStates = useStates(i18n.language as LanguageCodes);

  const defaultCountryObject = countries?.find(
    (country) => country.code === defaultCountry
  );

  const states =
    countryStates &&
    countryStates.filter((state) => {
      return state.country_id === Number(address.country)
        ? address.country
        : undefined;
    });

  if (states?.length === 0) {
    address.state = undefined;
  }

  if (!address.country && defaultCountryObject?.id) {
    address.country = defaultCountryObject?.id;
  }

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="country">{t('form.country')}</FormLabel>
        <Select
          id="country"
          placeholder={t('form.placeholder.select') || ''}
          onChange={onChange}
          value={`${address.country}`}
          isRequired>
          {countries &&
            countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
        </Select>
      </FormControl>

      {states && states.length > 0 && (
        <FormControl>
          <FormLabel htmlFor="state">{t('form.state')}</FormLabel>
          <Select
            id="state"
            placeholder={t('form.placeholder.select') || ''}
            value={`${address.state}`}
            onChange={onChange}
            isRequired>
            {states &&
              states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
          </Select>
        </FormControl>
      )}

      <SimpleGrid columns={[1, 2]} spacing={5}>
        <FormControl>
          <FormLabel htmlFor="postal_code">{t('form.postalCode')}</FormLabel>
          <Input
            id="postal_code"
            type="text"
            value={address.postal_code}
            onChange={onChange}
            isRequired
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="city_location">
            {t('form.cityLocation')}
          </FormLabel>
          <Input
            id="city_location"
            type="text"
            value={address.city_location}
            onChange={onChange}
            isRequired
          />
        </FormControl>
      </SimpleGrid>

      <FormControl>
        <FormLabel htmlFor="address_area">{t('form.addressArea')}</FormLabel>
        <Textarea
          id="address_area"
          value={address.address_area}
          onChange={onChange}
          isRequired
        />
        {showHelperText && (
          <FormHelperText>{t('form.addressAreaHelp')}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Address;

import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';
import { IpInfoContext } from '../../services/ipinfo';
import { useCountries } from '../../hooks/countries';
import {
  createAddress,
  createCompanyUser,
  createInvoice,
  createInvoiceMembership,
  createMembership,
  createMembershipInvoice,
  createUser,
  readInvoice,
  registerUser,
  updateMembership,
} from '../../services/directus';
import { scrollToView } from '../../utils/browser';
import { focusField, initForm } from '../../utils/form';
import { initialRegisterUser } from '../../utils/initial-values';
import { LanguageCodes } from '../../utils/language';
import {
  Addresses,
  DirectusUsers,
  InvoiceStatus,
  MembershipStatus,
  Users,
} from '../../utils/types';
import { Address } from '../Address/Address';
import { AlertBox } from '../AlertBox/AlertBox';
import { RegisterAgentInfo } from '../RegisterAgentInfo/RegisterAgentInfo';
import { RegisterCompanyInfo } from '../RegisterCompanyInfo/RegisterCompanyInfo';
import { SwitchTermsAndConditions } from '../SwitchTermsAndConditions/SwitchTermsAndConditions';
import { User } from '../User/User';

const LOCALSTORAGE_REGISTER_DATA = 'bonavendoRegisterData';
const LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS =
  'bonavendoTermsAndConditionsChecked';
const FOCUS_FIRST_FIELD = 'firstName';

interface RegisterMemberFormProps {
  agent?: DirectusUsers;
  companyId: number;
  companyName: string;
  isDisplayingAddress: boolean;
}

export const RegisterMemberForm = ({
  agent,
  companyId,
  companyName,
  isDisplayingAddress = false,
}: RegisterMemberFormProps) => {
  const { i18n, t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const { country } = useContext(IpInfoContext);
  const countries = useCountries(i18n.language as LanguageCodes);

  const storedUserData: Users = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_REGISTER_DATA) ||
      JSON.stringify(initialRegisterUser)
  );
  const storedTermsAndConditionsChecked = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS) || 'false'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    storedTermsAndConditionsChecked === true || false
  );
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [registerData, setRegisterData] = useState<Users>({
    user: storedUserData.user,
    address: storedUserData.address,
  });

  const defaultCountry = countries?.find((c) => c.code === country);

  const cleanupRegisterData = () => {
    setRegisterData({ ...initialRegisterUser });
    setSuccessfullyRegistered(true);
    setIsLoading(false);
    setIsChecked(false);
    localStorage.removeItem(LOCALSTORAGE_REGISTER_DATA);
    localStorage.removeItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    localStorage.setItem(
      LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS,
      JSON.stringify(!isChecked)
    );
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: 'user' | 'address'
  ) => {
    const user = registerData.user as DirectusUsers;
    const userObject = {
      ...user,
      ...(type === 'user'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const address = registerData.address as Addresses;
    const addressObject = {
      ...address,
      ...(type === 'address'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const changedRegisterData = {
      ...registerData,
      user: {
        ...userObject,
      },
      address: {
        ...addressObject,
      },
    };

    setRegisterData(changedRegisterData as Users);
    localStorage.setItem(
      LOCALSTORAGE_REGISTER_DATA,
      JSON.stringify(changedRegisterData)
    );
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    Promise.allSettled([
      registerUser(registerData.user as DirectusUsers),
      createAddress(registerData.address as Addresses),
    ])
      .then(([responseUser, responseAddress]: any) => {
        createUser({
          user: { id: responseUser.value.id },
          address:
            responseAddress.value.id === 0
              ? undefined
              : { id: responseAddress.value.id },
          agent: agent?.id,
          company: companyId,
        }).then((responseCreateUser: any) => {
          if (companyId) {
            createCompanyUser({
              users_id: responseCreateUser.id,
              companies_id: companyId,
            });
          }
          createMembership({ user: responseCreateUser.id }).then(
            (responseCreateMembership: any) => {
              // create invoice
              if (companyId === 0 && responseCreateMembership.id) {
                createInvoice({
                  recipient: companyId ? 'company' : 'user',
                  company: companyId ? companyId : undefined,
                  user: companyId ? null : responseCreateUser?.id,
                }).then((responseCreateInvoice: any) => {
                  createMembershipInvoice({
                    memberships_id: responseCreateMembership?.id,
                    invoices_id: responseCreateInvoice?.id,
                  }).then(() => {
                    createInvoiceMembership({
                      invoices_id: responseCreateInvoice?.id,
                      memberships_id: responseCreateMembership?.id,
                    }).then(() => {
                      cleanupRegisterData();
                      scrollToView('register-success');
                    });
                  });
                });
              }

              // update invoice
              if (companyId !== 0 && responseCreateMembership?.id) {
                readInvoice(companyId).then((responseReadInvoice: any) => {
                  if (
                    responseReadInvoice.data[0]?.status === InvoiceStatus.paid
                  ) {
                    updateMembership(responseCreateMembership.id, {
                      status: MembershipStatus.active,
                    });
                  }
                  createMembershipInvoice({
                    memberships_id: responseCreateMembership?.id,
                    invoices_id: responseReadInvoice.data[0]?.id,
                  }).then(() => {
                    createInvoiceMembership({
                      invoices_id: responseReadInvoice.data[0]?.id,
                      memberships_id: responseCreateMembership?.id,
                    }).then(() => {
                      cleanupRegisterData();
                      scrollToView('register-success');
                    });
                  });
                });
              }
            }
          );
        });
      })
      .catch((error) => {
        console.log('FAILED!', error);
      });
  };

  useEffect(() => {
    initForm();
    focusField(FOCUS_FIRST_FIELD);
  }, []);

  return (
    <Container>
      <Heading mt="20" mb="5">
        {t('form.titleRegisterMember')}
      </Heading>

      {successfullyRegistered && (
        <AlertBox
          status="success"
          title={t('common.alert.successfullyRegisteredTitle') || undefined}
          description={t('common.alert.successfullyRegisteredDescription')}
          onClose={() => setSuccessfullyRegistered(false)}
        />
      )}

      {!successfullyRegistered && agent && <RegisterAgentInfo agent={agent} />}

      {!successfullyRegistered && companyName && (
        <RegisterCompanyInfo companyName={companyName} />
      )}

      {!successfullyRegistered && (
        <form onSubmit={handleSubmit}>
          <Stack mb={marginBetweenSections} spacing="5">
            <User
              user={registerData.user as DirectusUsers}
              onChange={(event) => handleChange(event, 'user')}
            />

            {isDisplayingAddress && (
              <Address
                address={registerData.address as Addresses}
                countries={countries}
                defaultCountry={country}
                showHelperText={true}
                onChange={(event) => handleChange(event, 'address')}
              />
            )}

            <SwitchTermsAndConditions
              isChecked={isChecked}
              onChange={handleChecked}
            />

            <Button
              mt="4"
              size="lg"
              type="submit"
              disabled={!isChecked}
              isLoading={isLoading}>
              {companyId === 0
                ? t('form.registerWithCosts')
                : t('form.register')}
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
};

export type Addresses = {
  country?: number | Countries;
  state?: number | States;
  id?: number;
  postal_code?: string;
  city_location?: string;
  address_area?: string;
};

export type Companies = {
  address?: number | Addresses;
  agent?: string | DirectusUsers;
  cover_image?: string | DirectusFiles;
  industry?: number | Industries;
  manager?: string | DirectusUsers;
  user_created?: string | DirectusUsers;
  user_updated?: string | DirectusUsers;
  id?: number;
  slug?: string;
  company_id?: string;
  status?: string;
  date_created?: string;
  date_updated?: string;
  name?: string;
  url?: string;
  description?: string;
  intro?: string;
  map?: string | { coordinates: number[] | undefined };
  email_address?: string;
  phone_number?: string;
  tags?: string[];
  advantages?: string[];
  number_of_employees?: number;
  public_data?: string;
  meta_data?: string;
  sponsored_users?: string | CompaniesUsers[];
};

export type CompaniesUsers = {
  companies_id?: number | Companies;
  users_id?: number | Users;
  id?: number;
};

export type Countries = {
  id?: number;
  phone_code: number;
  code: string;
  name: string;
  currency: string | null;
  translations: string | CountriesTranslations[];
};

export type CountriesTranslations = {
  countries_id?: number | Countries;
  languages_code?: string | Languages;
  id?: number;
  name?: string;
};

export type Currencies = {
  id?: number;
  currency_code: string;
  currency_name: string;
  status?: string;
  translations: string | CurrenciesTranslations[];
};

export type CurrenciesTranslations = {
  currencies_id?: number | Currencies;
  languages_code?: string | Languages;
  id?: number;
  currency_name?: string;
};

export type Industries = {
  id?: number;
  name: string;
  translations: string | IndustriesTranslations[];
};

export type IndustriesTranslations = {
  industries_id?: number | Industries;
  languages_code?: string | Languages;
  id?: number;
  name?: string;
};

export type Invoices = {
  company?: number | Companies;
  currency?: number | Currencies;
  user_created?: string | DirectusUsers;
  user?: number | Users;
  user_updated?: string | DirectusUsers;
  id?: number;
  status?: string;
  date_created?: string;
  date_updated?: string;
  date_payed?: string;
  amount?: string;
  recipient?: string;
  invoice_id?: string;
  number_of_employees?: number;
  memberships?: string | InvoicesMemberships[];
};

export type InvoicesMemberships = {
  invoices_id?: number | Invoices;
  memberships_id?: number | Memberships;
  id?: number;
};

export type Languages = {
  code: string;
  name?: string;
};

export type Memberships = {
  user?: number | Users;
  id?: number;
  status?: string;
  date_created?: string;
  date_updated?: string;
  invoices?: string | MembershipsInvoices[];
};

export type MembershipsInvoices = {
  invoices_id?: number | Invoices;
  memberships_id?: number | Memberships;
  id?: number;
};

export type Pages = {
  cover_image?: string | DirectusFiles;
  user_created?: string | DirectusUsers;
  user_updated?: string | DirectusUsers;
  id?: number;
  slug?: string;
  status?: string;
  sort?: number;
  date_created?: string;
  date_updated?: string;
  title?: string;
  body?: string;
  languages_id?: string;
  display_date_updated?: boolean;
  translations: string | PagesTranslations[];
};

export type PagesTranslations = {
  languages_id?: string | Languages;
  Pages_id?: number | Pages;
  id?: number;
  title?: string;
  body?: string;
};

export type Regions = {
  id?: number;
  name: string;
  translations: string | RegionsTranslations[];
  countries: string | RegionsCountries[];
};

export type RegionsCountries = {
  countries_id?: number | Countries;
  regions_id?: number | Regions;
  id?: number;
};

export type RegionsTranslations = {
  languages_code?: string | Languages;
  regions_id?: number | Regions;
  id?: number;
  name?: string;
};

export type States = {
  country_id?: number | Countries;
  id?: number;
  code: string;
  name: string;
  translations: string | StatesTranslations[];
};

export type StatesTranslations = {
  languages_code?: string | Languages;
  states_id?: number | States;
  id?: number;
  name?: string;
};

export type Users = {
  address?: number | Addresses;
  agent?: string | DirectusUsers;
  company?: number | Companies;
  user_created?: string | DirectusUsers;
  user?: string | DirectusUsers;
  user_updated?: string | DirectusUsers;
  id?: number;
  date_created?: string;
  date_updated?: string;
};

export type DirectusActivity = {
  id?: number;
  action: string;
  user?: string | DirectusUsers;
  timestamp: string;
  ip?: string;
  user_agent?: string;
  collection: string;
  item: string;
  comment?: string;
  origin?: string;
  revisions: string | DirectusRevisions[];
};

export type DirectusCollections = {
  group?: string | DirectusCollections;
  collection: string;
  icon?: string;
  note?: string;
  display_template?: string;
  hidden: boolean;
  singleton: boolean;
  translations?: unknown;
  archive_field?: string;
  archive_app_filter: boolean;
  archive_value?: string;
  unarchive_value?: string;
  sort_field?: string;
  accountability?: string;
  color?: string;
  item_duplication_fields?: unknown;
  sort?: number;
  collapse: string;
  collection_divider: string;
  archive_divider: string;
  sort_divider: string;
  accountability_divider: string;
  duplication_divider: string;
};

export type DirectusFields = {
  id?: number;
  collection: string | DirectusCollections;
  field: string;
  special?: unknown;
  interface?: string;
  options?: unknown;
  display?: string;
  display_options?: unknown;
  readonly: boolean;
  hidden: boolean;
  sort?: number;
  width?: string;
  translations?: unknown;
  note?: string;
  conditions?: unknown;
  required?: boolean;
  group?: string | DirectusFields;
  validation?: unknown;
  validation_message?: string;
};

export type DirectusFiles = {
  folder?: string | DirectusFolders;
  modified_by?: string | DirectusUsers;
  uploaded_by?: string | DirectusUsers;
  id: string;
  storage: string;
  filename_disk?: string;
  filename_download: string;
  title?: string;
  type?: string;
  uploaded_on: string;
  modified_on: string;
  charset?: string;
  filesize?: number;
  width?: number;
  height?: number;
  duration?: number;
  embed?: string;
  description?: string;
  location?: string;
  tags?: unknown;
  metadata?: unknown;
  storage_divider: string;
};

export type DirectusFolders = {
  parent?: string | DirectusFolders;
  id: string;
  name: string;
};

export type DirectusMigrations = {
  version: string;
  name: string;
  timestamp: string;
};

export type DirectusPermissions = {
  role?: string | DirectusRoles;
  id?: number;
  collection: string;
  action: string;
  permissions?: unknown;
  validation?: unknown;
  presets?: unknown;
  fields?: unknown;
};

export type DirectusPresets = {
  role?: string | DirectusRoles;
  user?: string | DirectusUsers;
  id?: number;
  bookmark?: string;
  collection?: string;
  search?: string;
  layout?: string;
  layout_query?: unknown;
  layout_options?: unknown;
  refresh_interval?: number;
  filter?: unknown;
  icon: string;
  color?: string;
};

export type DirectusRelations = {
  id?: number;
  many_collection: string;
  many_field: string;
  one_collection?: string;
  one_field?: string;
  one_collection_field?: string;
  one_allowed_collections?: unknown;
  junction_field?: string;
  sort_field?: string;
  one_deselect_action: string;
};

export type DirectusRevisions = {
  activity: number | DirectusActivity;
  parent?: number | DirectusRevisions;
  id?: number;
  collection: string;
  item: string;
  data?: unknown;
  delta?: unknown;
};

export type DirectusRoles = {
  id: string;
  name: string;
  icon: string;
  description?: string;
  ip_access?: unknown;
  enforce_tfa: boolean;
  admin_access: boolean;
  app_access: boolean;
  users: string | DirectusUsers[];
};

export type DirectusSessions = {
  share?: string | DirectusShares;
  user?: string | DirectusUsers;
  token: string;
  expires: string;
  ip?: string;
  user_agent?: string;
  origin?: string;
};

export type DirectusSettings = {
  project_logo?: string | DirectusFiles;
  public_background?: string | DirectusFiles;
  public_foreground?: string | DirectusFiles;
  storage_default_folder?: string | DirectusFolders;
  id?: number;
  project_name: string;
  project_url?: string;
  project_color?: string;
  public_note?: string;
  auth_login_attempts?: number;
  auth_password_policy?: string;
  storage_asset_transform?: string;
  storage_asset_presets?: unknown;
  custom_css?: string;
  basemaps?: unknown;
  mapbox_key?: string;
  module_bar?: unknown;
  project_descriptor?: string;
  translation_strings?: unknown;
  default_language: string;
  custom_aspect_ratios?: unknown;
  branding_divider: string;
  modules_divider: string;
  security_divider: string;
  files_divider: string;
  map_divider: string;
  image_editor: string;
};

export type DirectusUsers = {
  role?: string | DirectusRoles;
  id?: string;
  external_identifier?: string;
  email?: string;
  token?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  location?: string;
  title?: string;
  description?: string;
  tags?: unknown;
  avatar?: string | DirectusFiles;
  language?: string;
  theme?: string;
  tfa_secret?: string;
  status?: string;
  last_access?: string;
  last_page?: string;
  provider?: string;
  auth_data?: string;
  email_notifications?: boolean;
  preferences_divider?: string;
  admin_divider?: string;
};

export type DirectusWebhooks = {
  id?: number;
  name: string;
  method: string;
  url: string;
  status: string;
  data: boolean;
  actions: unknown;
  collections: unknown;
  headers?: unknown;
  triggers_divider: string;
};

export type DirectusDashboards = {
  user_created?: string | DirectusUsers;
  id: string;
  name: string;
  icon: string;
  note?: string;
  date_created: string;
  color?: string;
  panels: string | DirectusPanels[];
};

export type DirectusPanels = {
  dashboard: string | DirectusDashboards;
  user_created?: string | DirectusUsers;
  id: string;
  name?: string;
  icon?: string;
  color?: string;
  show_header: boolean;
  note?: string;
  type: string;
  position_x: number;
  position_y: number;
  width: number;
  height: number;
  options?: unknown;
  date_created: string;
};

export type DirectusNotifications = {
  recipient: string | DirectusUsers;
  sender?: string | DirectusUsers;
  id?: number;
  timestamp?: string;
  status?: string;
  subject: string;
  message?: string;
  collection?: string;
  item?: string;
};

export type DirectusShares = {
  collection?: string | DirectusCollections;
  role?: string | DirectusRoles;
  user_created?: string | DirectusUsers;
  id: string;
  name?: string;
  item?: string;
  password?: string;
  date_created: string;
  date_start?: string;
  date_end?: string;
  times_used?: number;
  max_uses?: number;
};

export type DirectusFlows = {
  user_created?: string | DirectusUsers;
  id: string;
  operation?: string | DirectusOperations;
  name: string;
  icon?: string;
  color?: string;
  description?: string;
  status: string;
  trigger?: string;
  accountability?: string;
  options?: unknown;
  date_created: string;
  operations: string | DirectusOperations[];
};

export type DirectusOperations = {
  flow: string | DirectusFlows;
  reject?: string | DirectusOperations;
  resolve?: string | DirectusOperations;
  user_created?: string | DirectusUsers;
  id: string;
  name?: string;
  key: string;
  type: string;
  position_x: number;
  position_y: number;
  options?: unknown;
  date_created: string;
};

export type CustomDirectusTypes = {
  addresses: Addresses;
  companies: Companies;
  companies_users: CompaniesUsers;
  countries: Countries;
  countries_translations: CountriesTranslations;
  currencies: Currencies;
  currencies_translations: CurrenciesTranslations;
  industries: Industries;
  industries_translations: IndustriesTranslations;
  invoices: Invoices;
  invoices_memberships: InvoicesMemberships;
  languages: Languages;
  memberships: Memberships;
  memberships_invoices: MembershipsInvoices;
  Pages: Pages;
  Pages_translations: PagesTranslations;
  regions: Regions;
  regions_countries: RegionsCountries;
  regions_translations: RegionsTranslations;
  states: States;
  states_translations: StatesTranslations;
  users: Users;
  directus_activity: DirectusActivity;
  directus_collections: DirectusCollections;
  directus_fields: DirectusFields;
  directus_files: DirectusFiles;
  directus_folders: DirectusFolders;
  directus_migrations: DirectusMigrations;
  directus_permissions: DirectusPermissions;
  directus_presets: DirectusPresets;
  directus_relations: DirectusRelations;
  directus_revisions: DirectusRevisions;
  directus_roles: DirectusRoles;
  directus_sessions: DirectusSessions;
  directus_settings: DirectusSettings;
  directus_users: DirectusUsers;
  directus_webhooks: DirectusWebhooks;
  directus_dashboards: DirectusDashboards;
  directus_panels: DirectusPanels;
  directus_notifications: DirectusNotifications;
  directus_shares: DirectusShares;
  directus_flows: DirectusFlows;
  directus_operations: DirectusOperations;
};

export type LoginProps = {
  email: string;
  password: string;
};

export type IpInfo = {
  city: string;
  country: string;
  ip: string;
  loc: string;
  org: string;
  postal: string;
  region: string;
  timezone: string;
};

export enum Status {
  draft = 'draft',
  active = 'active',
  published = 'published',
  archived = 'archived',
}

export enum InvoiceStatus {
  draft = 'draft',
  sended = 'sended',
  reminded = 'reminded',
  paid = 'paid',
  archived = 'archived',
}

export enum MembershipStatus {
  pending = 'pending',
  active = 'active',
  expired = 'expired',
  archived = 'archived',
}

export type AuthProps = {
  isLoading: boolean;
  userData: DirectusUsers;
  isAuthenticated: boolean;
  isMember: boolean;
  isManager: boolean;
  isAgent: boolean;
  errorStatus: string | undefined;
  closeErrorStatus: () => void;
  login: ({ email, password }: { email: string; password: string }) => void;
  logout: (event: any) => void;
  getToken: () => void;
};

import {
  Button,
  DarkMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  LanguageCodes,
  languageNamesInNationalLanguage,
} from '../../utils/language';

export const LanguageMenu = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Menu>
      <DarkMode>
        <MenuButton as={Button} size="sm" variant={['outline', 'ghost']}>
          {
            Object.keys(LanguageCodes)[
              Object.values(LanguageCodes).indexOf(
                `${i18n.language}` as LanguageCodes
              )
            ]
          }
        </MenuButton>
      </DarkMode>
      <MenuList minW="80px" color={useColorModeValue('gray.900', 'white')}>
        {Object.entries(LanguageCodes).map(([key, value]) => (
          <MenuItem key={key} onClick={() => changeLanguage(value)}>
            {(languageNamesInNationalLanguage as any)[key]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

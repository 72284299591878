import { userRole } from '../services/directus';
import {
  Addresses,
  Companies,
  DirectusUsers,
  LoginProps,
  IpInfo,
  Users,
  AuthProps,
} from './types';

export const initialUser: DirectusUsers = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
};

export const initialAddress: Addresses = {
  id: 0,
  country: undefined,
  state: undefined,
  postal_code: '',
  city_location: '',
  address_area: '',
};

export const initialRegisterUser: Users = {
  user: { ...initialUser, role: userRole.user },
  address: { ...initialAddress },
};

export const initialLogin: LoginProps = {
  email: '',
  password: '',
};

export const initialCompany: Companies = {
  manager: { ...initialUser },
  address: { ...initialAddress },
  name: '',
  number_of_employees: 0,
};

export const initialIpInfo: IpInfo = {
  city: '',
  country: '',
  ip: '',
  loc: '',
  org: '',
  postal: '',
  region: '',
  timezone: '',
};

export const initialAuthContext: AuthProps = {
  isLoading: false,
  userData: initialUser,
  isAuthenticated: false,
  isMember: false,
  isManager: false,
  isAgent: false,
  errorStatus: undefined,
  closeErrorStatus: () => '',
  login: () => '',
  logout: () => '',
  getToken: () => '',
};

import {
  Box,
  Button,
  Container,
  DarkMode,
  Flex,
  Hide,
  HStack,
  Link,
  Show,
  Spacer,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import {
  URL_LOGIN,
  URL_PRICING,
  URL_REGISTER_COMPANY,
  URL_REGISTER_MEMBER,
  URL_ROOT,
} from '../../utils/constant';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import { ColorModeSwitcher } from '../ColorModeSwitcher/ColorModeSwitcher';
import { HeaderMenu } from '../HeaderMenu/HeaderMenu';
import { LanguageMenu } from '../LanguageMenu/LanguageMenu';
import { LogoIcon } from '../Logo/Logo';
import { SearchWhatAndWhere } from '../SearchWhatAndWhere/SearchWhatAndWhere';

export const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isHome = location.pathname === '/';

  const logoColor = useColorModeValue('white', '#e63e62');
  const logoMargin = useBreakpointValue({ base: '1px 0 0', sm: '0' });
  const logoWidth = useBreakpointValue({ base: '160px', sm: '180px' });
  const HeaderLogo = () => (
    <BonavendoLogo color={logoColor} margin={logoMargin} width={logoWidth} />
  );

  return (
    <Flex
      bgColor={useColorModeValue('bonavendo.red', 'green.900')}
      color={'gray.200'}
      w="full">
      <Container maxW="6xl" py="3">
        <header>
          <Flex mb={isHome ? '10' : '0'}>
            <Box mt={['2', '1']}>
              <Show below="md">
                <Link href={URL_ROOT}>
                  {isHome ? (
                    <HeaderLogo />
                  ) : (
                    <Box mt="-3">
                      <LogoIcon color={logoColor} />
                    </Box>
                  )}
                </Link>
              </Show>
              <Hide below="md">
                <HStack spacing="4">
                  <Link href={URL_ROOT}>
                    <HeaderLogo />
                  </Link>
                  <DarkMode>
                    <Button
                      as={NavLink}
                      to={URL_REGISTER_COMPANY}
                      size="sm"
                      variant="outline">
                      {t('common.registerCompany')}
                    </Button>
                    <Button
                      as={NavLink}
                      to={URL_PRICING}
                      size="sm"
                      variant="ghost">
                      {t('common.pricing')}
                    </Button>
                  </DarkMode>
                </HStack>
              </Hide>
            </Box>

            <Spacer />

            <Box textAlign="center" mt="1">
              <LanguageMenu />
            </Box>

            <Spacer />

            <Box textAlign="right">
              <ColorModeSwitcher />

              <Hide below="md">
                <DarkMode>
                  <Button as={NavLink} to={URL_LOGIN} size="sm" variant="ghost">
                    {t('common.login')}
                  </Button>
                  <Button
                    as={NavLink}
                    mx="4"
                    size="sm"
                    to={URL_REGISTER_MEMBER}
                    variant="outline">
                    {t('common.registerMember')}
                  </Button>
                </DarkMode>
              </Hide>
              <Show below="md">
                <HeaderMenu />
              </Show>
            </Box>
          </Flex>

          {isHome && (
            <Box maxW="xl" mb="10" mx="auto">
              <SearchWhatAndWhere />
            </Box>
          )}
        </header>
      </Container>
    </Flex>
  );
};

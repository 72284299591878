import { useState } from 'react';
import { readStates, readStatesTranslations } from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { States, StatesTranslations } from '../utils/types';

export const useStates = (currentLanguage: LanguageCodes) => {
  const [states, setStates] = useState<States[]>();
  const [language, setLanguage] = useState<LanguageCodes>();

  const fetchStates = async () =>
    await readStates().then(async (responseStates: any) => {
      const statesData = responseStates.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setStates(statesData);
        setLanguage(currentLanguage);
      }

      if (currentLanguage !== DEFAULT_LANGUAGE) {
        await readStatesTranslations().then(
          (responseStatesTranslations: any) => {
            const statesTranslationsData = responseStatesTranslations.data;

            statesTranslationsData.map((translation: StatesTranslations) => {
              if (translation.languages_code === currentLanguage) {
                const statesIndex = statesData.findIndex(
                  (state: States) => state.id === translation.states_id
                );

                statesData[statesIndex].name = translation.name;

                setStates(statesData as States[]);
                setLanguage(currentLanguage);
              }
            });
          }
        );
      }
    });

  if (currentLanguage !== language) {
    fetchStates();
  }

  return states;
};

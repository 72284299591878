import { LoginForm } from '../components/LoginForm/LoginForm';

export const Login = () => {
  return (
    <main className="login">
      <section>
        <LoginForm />
      </section>
    </main>
  );
};

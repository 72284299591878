import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

export const Input: ComponentStyleConfig = {
  parts: ['field'],
  variants: {
    outline: (props) => ({
      field: {
        border: '2px solid',
        borderColor: mode('gray.500', 'gray.400')(props),
        opacity: '0.8',
        _hover: {
          borderColor: mode('gray.600', 'gray.600')(props),
        },
        _focus: {
          bg: 'bonavendo.yellow',
          borderColor: 'yellow.500',
          boxShadow: 'none',
          color: 'gray.900',
          opacity: '1',

          _placeholder: {
            color: 'gray.900',
          },
        },
        _focusVisible: {
          backgroundColor: 'rgba(255,255,255,0.15)',
          outline: 'none',
        },
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};

export default Input;

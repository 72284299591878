import { createContext, ReactNode, useEffect, useState } from 'react';
import { LOCALSTORAGE_USER_DATA } from '../utils/constant';
import { initialAuthContext, initialUser } from '../utils/initial-values';
import { DirectusUsers } from '../utils/types';
import {
  loginUser,
  // logoutUser,
  readLoggedInUser,
  refreshToken,
  refreshTokenIfExpired,
} from './directus';

const {
  REACT_APP_MEMBER_USER_ROLE_ID,
  REACT_APP_MEMBER_MANAGER_ROLE_ID,
  REACT_APP_MEMBER_AGENT_ROLE_ID,
} = process.env;

export const AuthContext = createContext(initialAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [errorStatus, setErrorStatus] = useState();
  const [userData, setUserData] = useState<DirectusUsers>(initialUser);
  const loggedInUserData = initialUser;

  const getUserData = () => {
    readLoggedInUser()
      .then((responseUser: any) => {
        console.log({ responseUser });
        // if (!responseUser) {
        //   logout();
        // }

        loggedInUserData.email = responseUser.email ?? '';
        loggedInUserData.first_name = responseUser.first_name ?? '';
        loggedInUserData.id = responseUser.id ?? '';
        loggedInUserData.last_name = responseUser.last_name ?? '';
        loggedInUserData.role = responseUser.role ?? '';
        loggedInUserData.status = responseUser.status ?? '';

        switch (loggedInUserData.role) {
          case REACT_APP_MEMBER_USER_ROLE_ID:
            setIsMember(true);
            break;

          case REACT_APP_MEMBER_MANAGER_ROLE_ID:
            setIsManager(true);
            break;

          case REACT_APP_MEMBER_AGENT_ROLE_ID:
            setIsAgent(true);
            break;

          default:
            break;
        }

        localStorage.setItem(
          LOCALSTORAGE_USER_DATA,
          JSON.stringify(loggedInUserData)
        );
        setUserData(loggedInUserData);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log({ error });
        if (error && error.response && error.response.status) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(undefined);
        }
        // logout();
      });
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   getUserData();
  // }, [setIsLoading, getUserData]);

  const closeErrorStatus = () => {
    setErrorStatus(undefined);
  };

  const login = ({ email, password }: { email: string; password: string }) => {
    setIsLoading(true);
    loginUser(email, password).then((responseLogin) => {
      console.log({ responseLogin });
      if (responseLogin.access_token) {
        getUserData();
      }
    });
  };

  const logout = () => {
    setUserData(initialUser);
    setIsAuthenticated(false);
    setIsLoading(false);
    setIsMember(false);
    setIsManager(false);
    setIsAgent(false);
    // logoutUser();
  };

  const getToken = () => {
    // refreshTokenIfExpired()
    //   .then((responseToken) => {
    //     console.log(responseToken);
    //     // return responseToken.access_token;
    //   })
    //   .catch(() => {
    //     console.log('no token');
    //     setUserData(initialUser);
    //     setIsAuthenticated(false);
    //   });
    refreshToken()
      .then((responseToken) => {
        console.log(responseToken);
        setIsAuthenticated(true);
        return responseToken;
      })
      .catch(() => {
        console.log('no token');
        setUserData(initialUser);
        setIsAuthenticated(false);
      });
  };

  const checkAuth = () => {
    // refreshToken().then((response) => {
    //   console.log(response);
    // });
    readLoggedInUser()
      .then((user) => {
        console.log({ user });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const authToken = window.localStorage.getItem('auth_token');
  console.log({ authToken });

  const authExpiresAt = window.localStorage.getItem('auth_expires_at');
  console.log({ authExpiresAt });

  const userData2 = window.localStorage.getItem('userData');
  console.log({ userData2 });

  useEffect(() => {
    checkAuth();
    //   getToken();
    // getUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userData,
        isAuthenticated,
        isMember,
        isManager,
        isAgent,
        errorStatus,
        closeErrorStatus,
        login,
        logout,
        getToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  DarkMode,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  MdOutlineLogin,
  MdPersonAddAlt1,
  MdAdd,
  MdEuroSymbol,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  URL_LOGIN,
  URL_PRICING,
  URL_REGISTER_COMPANY,
  URL_REGISTER_MEMBER,
} from '../../utils/constant';

export const HeaderMenu = () => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('gray.900', 'white');

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <DarkMode>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Options"
              icon={
                isOpen ? (
                  <CloseIcon h="4" w="6" />
                ) : (
                  <HamburgerIcon h="6" w="6" />
                )
              }
              variant="outline"
            />
          </DarkMode>
          <MenuList color={textColor}>
            <MenuItem as={NavLink} to={URL_REGISTER_MEMBER}>
              <Icon as={MdPersonAddAlt1} mr="2" />
              {t('common.registerMember')}
            </MenuItem>
            <MenuItem as={NavLink} to={URL_REGISTER_COMPANY}>
              <Icon as={MdAdd} mr="2" />
              {t('common.registerCompany')}
            </MenuItem>
            <MenuItem as={NavLink} to={URL_PRICING}>
              <Icon as={MdEuroSymbol} mr="2" />
              {t('common.pricing')}
            </MenuItem>
            <MenuItem as={NavLink} to={URL_LOGIN}>
              <Icon as={MdOutlineLogin} mr="2" />
              {t('common.login')}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

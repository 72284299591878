import {
  Button,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Input,
  Icon,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { MdAccountBalance, MdOutlineInfo, MdEmail } from 'react-icons/md';
import { globalDefaultContext } from '../../contexts/GlobalContext';
import {
  URL_ABOUT_US,
  URL_CONTACT,
  URL_IMPRINT,
  URL_PRIVACY,
  URL_ROOT,
  URL_STATUTES,
  URL_TERMS,
} from '../../utils/constant';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';

const ListHeader = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon: IconType;
}) => {
  return (
    <Text fontWeight="500" fontSize="lg" mb="2">
      <Icon as={icon} mr="2" mb="-5px" h="6" w="6" />
      <span>{children}</span>
    </Text>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const logoColor = useColorModeValue('white', '#e63e62');
  const normalBorderColor = useColorModeValue(
    'whiteAlpha.800',
    'whiteAlpha.300'
  );

  return (
    <Flex
      bgColor={useColorModeValue('bonavendo.red', 'green.900')}
      color={'gray.200'}
      py={globalDefaultContext.marginBetweenSections}
      w="full">
      <Container as={Stack} maxW="6xl" textAlign={['center', 'left']}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Flex justifyContent={['center', 'flex-start']}>
              <Link href={URL_ROOT}>
                <BonavendoLogo color={logoColor} />
              </Link>
            </Flex>
            <Text fontSize="sm">
              &copy; {new Date().getFullYear()} {t('common.bonavendoFull')}
              .&nbsp;
              {t('common.copyright')}
            </Text>
          </Stack>
          <Stack>
            <ListHeader icon={MdAccountBalance}>
              {t('common.association')}
            </ListHeader>
            <Link href={URL_ABOUT_US}>{t('common.about')}</Link>
            <Link href={URL_STATUTES}>{t('common.statutes')}</Link>
            <Link href={URL_CONTACT}>{t('common.contact')}</Link>
          </Stack>
          <Stack>
            <ListHeader icon={MdOutlineInfo}>{t('common.support')}</ListHeader>
            <Link href="/musterfirma">Musterfirma</Link>
            <Link href={URL_IMPRINT}>{t('common.imprint')}</Link>
            <Link href={URL_PRIVACY}>{t('common.dataProtection')}</Link>
            <Link href={URL_TERMS}>{t('common.termsOfUse')}</Link>
          </Stack>
          <Stack>
            <ListHeader icon={MdEmail}>
              {t('common.newsletterHeadline')}
            </ListHeader>
            <Text fontSize="sm">{t('common.newsletterDescription')}</Text>
            <Stack direction="row">
              <Input
                borderRadius="base"
                size="sm"
                type="email"
                borderColor={normalBorderColor}
                _hover={{
                  borderColor: useColorModeValue('white', 'whiteAlpha.500'),
                }}
                placeholder={t('common.emailAddress') || ''}
                _placeholder={{ color: 'white' }}
              />
              <Button size="sm">{t('common.subscribe')}</Button>
            </Stack>
            <Text fontSize="xs">{t('common.newsletterPromise')}</Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

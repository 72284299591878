import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import ThinkingSmiley from '../../assets/thinking-smiley.png';
import GlobalContext from '../../contexts/GlobalContext';

export const Error = () => {
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const logoColor = useColorModeValue('#FF1F40', 'white');
  const marginBottom = 10;

  return (
    <Container maxW={'sm'}>
      <Box mt={[10, 20]} mb={marginBottom}>
        <Link href="/">
          <BonavendoLogo color={logoColor} margin="0 auto" />
        </Link>
      </Box>

      <Box mb={marginBottom} textAlign="center">
        <Heading>{t('error.404.title')}</Heading>
        <Text>{t('error.404.description')}</Text>
        <Image src={ThinkingSmiley} h="100" w="100" mx={'auto'} my={10} />
        <Link href="/">
          <Button>{t('common.home')}</Button>
        </Link>
      </Box>

      <Box mb={marginBottom} textAlign="center">
        <Trans i18nKey="common.noAccount">
          New to bonavendo?&nsbp;
          <Link href="/register" textDecoration="underline">
            Join Now!
          </Link>
        </Trans>
      </Box>

      <Flex fontSize="xs" mb={marginBetweenSections}>
        <Link noOfLines={1} href="/page/imprint">
          {t('common.imprint')}
        </Link>
        <Spacer />
        <Link noOfLines={1} href="/page/privacy">
          {t('common.dataProtection')}
        </Link>
        <Spacer />
        <Link noOfLines={1} href="/page/termsofuse">
          {t('common.termsOfUse')}
        </Link>
      </Flex>
    </Container>
  );
};

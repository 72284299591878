import {
  Box,
  VStack,
  Button,
  Flex,
  chakra,
  Grid,
  GridItem,
  Container,
  Text,
  Heading,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import { globalDefaultContext } from '../../contexts/GlobalContext';

const { REACT_APP_BETA_PHASE_START_DATE } = process.env;

interface FeatureProps {
  active?: boolean;
  heading: string;
  text: string;
}

const optionsAmount = 24;
const options = Array.from(Array(24).keys());
const startDate = new Date(`${REACT_APP_BETA_PHASE_START_DATE}`);
const currentDate = new Date();
const timeBetween =
  currentDate.getMonth() -
  startDate.getMonth() +
  12 * (currentDate.getFullYear() - startDate.getFullYear());

const Feature = ({ active, heading, text }: FeatureProps) => {
  return (
    <GridItem
      border={active ? '2px solid' : '0 none'}
      borderColor={'gray.500'}
      borderRadius={'base'}
      opacity={active ? '1' : '0.4'}
      p={4}
      textAlign={'center'}>
      <chakra.h3 fontSize="xl" fontWeight="600" whiteSpace={'nowrap'}>
        {heading}
      </chakra.h3>
      <chakra.p whiteSpace={'nowrap'}>{text}</chakra.p>
    </GridItem>
  );
};

export const BetaPhase = () => {
  const currentOptions = optionsAmount - timeBetween + 1;
  const isBetaPhase = currentOptions > 0;

  return isBetaPhase ? (
    <Flex
      bgColor={useColorModeValue('green.100', 'blue.900')}
      py={globalDefaultContext.marginBetweenSections}
      w={'full'}>
      <Container maxW={'6xl'}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          gap={4}
          mb={6}>
          <GridItem colSpan={1}>
            <VStack alignItems={['center', 'flex-start']} spacing={5}>
              <Heading>Anteile für die Ersten</Heading>
              <Stack direction={['column', 'row']} spacing={5} align={'center'}>
                <Button size="md">Mitglied werden</Button>
                <Text>
                  ...und <strong>{currentOptions} Optionen</strong> sichern!
                </Text>
              </Stack>
            </VStack>
          </GridItem>
          <GridItem>
            <Flex>
              <Text>
                Mitglieder der ersten Stunde bekommen Anteile am Bonavendo-Coin.
                Der Coin basiert auf der Blockchain-Technologie und befindet
                sich in der Entwicklung. Das Angebot besteht zwei Jahre, sprich
                24 Monate. Jede Phase hat die Länge eines Monats. Mitglieder in
                der ersten Phase (M&nbsp;1) bekommen 24 Optionen, Mitglieder der
                darauf folgenden Phase (M&nbsp;2) bekommen 23 Optionen und so
                fort. Wer früh dabei ist, sichert sich mehr Optionen.
              </Text>
            </Flex>
          </GridItem>
        </Grid>

        <Box my={10}>
          <Heading fontSize={'2xl'} textAlign={['center', 'left']}>
            Aktueller Phase
          </Heading>
        </Box>

        <Grid
          templateColumns={{
            base: 'repeat(3, 1fr)',
            sm: 'repeat(4, 1fr)',
            md: 'repeat(6, 1fr)',
            lg: 'repeat(8, 1fr)',
          }}
          gap={{ base: '4' }}>
          {options.map((index) => (
            <Feature
              key={index}
              active={index + 1 === timeBetween}
              heading={`M ${index + 1}`}
              text={`${optionsAmount - index} Opt.`}
            />
          ))}
        </Grid>
      </Container>
    </Flex>
  ) : (
    <></>
  );
};

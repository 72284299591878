import { Container, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BounceLoader from 'react-spinners/BounceLoader';

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <Container centerContent py="20">
      <Text mb="5">{t('common.loadingPage')}</Text>
      <BounceLoader color="var(--chakra-colors-brand-400)" size={60} />
    </Container>
  );
};
